<template lang="pug">
button.base-btn
  slot
</template>

<script>
export default {
  // setup() {
  //   const { isMobile } = useScreenSizeChecker()

  //   return {
  //     isMobile,
  //   }
  // },
  props: {
    css: {
      type: Object,
      default: () => ({})
    },
  },
  // computed: {
  //   btnStyles() {
  //     return {
        // '--padding': this.css.padding ? this.css.padding : '15px',
        // '--bg-color': this.css.backgroundColor ? this.css.backgroundColor : '#00A4FF',
        // '--bg-color-hover': this.css.backgroundColor ? this.css.backgroundColor : '#5426E2',
        // '--max-width': this.css.maxWidth ? this.css.maxWidth : 'auto',
        // '--btn-width': this.isMobile ? '100%' : (this.css.padding ? 'auto' : (this.css.width ? this.css.width : '217px')),
        // '--text-color': this.css.color ? this.css.color : '#fff',
        // '--font-size': this.css.fontSize ? this.css.fontSize : '12px',
        // '--font-weight': this.css.fontWeight ? this.css.fontWeight : '300',
  //     }
  //   }
  // },
}
</script>

<style lang="scss" scoped>
.base-btn {
  // padding: var(--padding);
  // max-width: var(--max-width);

  border: none;
  color: var(--btn-text-color);
  text-transform: uppercase;
  background-color: var(--btn-bcg-color);
  cursor: pointer;
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  width: var(--btn-width);
  height: var(--btn-height);

  &:hover {
    background-color: var(--btn-bcg-hover-color);
  }

  // @media only screen and (min-width: 768px) and (max-width: 1023px) {
  //   width: 217px;
  // }

  // @media (min-width: 1024px) and (max-width: 1279px) {
  //   width: 217px;
  // }

  // @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  //   width: 217px;
  // }
}
</style>
